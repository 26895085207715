
import { Component, Vue, Watch } from 'vue-property-decorator';

import { vxm } from '@/store';

import SidebarPanel from './sidebar/sidebar-panel.vue';

@Component({
    components: {
        SidebarPanel
    }
})
export default class BaseSidebar extends Vue {
    @Watch('$route')
    private routeChange(route: any, oldRoute: any) {
        // We close the sidebar when the route changes
        this.hideSidebar();
    }

    get collapsed(): boolean {
        return vxm.app.sidebarCollapsed;
    }

    private hideSidebar() {
        vxm.app.hideSidebar();
    }

    private showSidebar() {
        vxm.app.showSidebar();
    }

    private toggleSidebar(event: MouseEvent) {
        const elem = event.target as HTMLElement;
        if (this.isChildOfExpandMenu(elem)) {
            this.showSidebar();
        } else if (this.isChildOfLink(elem)) {
            this.hideSidebar();
        } else if (elem.localName === 'input') {
            this.showSidebar();
        } else if (vxm.app.sidebarCollapsed && elem.innerHTML === '') {
            this.showSidebar();
        } else {
            this.hideSidebar();
        }
    }

    private isChildOfExpandMenu(elem: HTMLElement): boolean {
        if (elem == null) {
            return false;
        }
        if (elem.className.includes && elem.className.includes('expand-menu')) {
            return true;
        } else if (elem.parentElement != null) {
            return this.isChildOfExpandMenu(elem.parentElement);
        }
        return false;
    }

    private isChildOfLink(elem: HTMLElement): boolean {
        if (elem == null) {
            return false;
        }
        if (elem.hasAttribute('href')) {
            return true;
        } else if (elem.parentElement != null) {
            return this.isChildOfLink(elem.parentElement);
        }
        return false;
    }
}
